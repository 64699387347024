@import "../../variables.scss";

.topimage {
  position: relative;
  height: 400px;
  overflow: hidden;
  color: $white;
  margin-top: 0 !important;
  padding-top: 3rem;
  width: 100%;

  .image-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  .parallax {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: right top -130px;
    background-size: cover;
    transition: opacity 0.5s ease-in-out;
    background-attachment: fixed;
    opacity: 0;

    @media (max-width: 768px) {
      background-size: cover;
      background-position: right -140px top -60px;
    }

    opacity: 1;
    z-index: 2;
  }
}
