@import "../../variables.scss";

.service-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 10rem;
  margin: 0 auto 3rem;
}

.service-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

p {
  font-weight: 100;
  line-height: 1.6;
  font-size: 1.2rem;
  letter-spacing: 0.8px;
}

h3 {
  color: $green;
  font-weight: 700;
}

.service-trenner-img {
  width: 100%;
  max-width: 100%;
  position: relative;
  padding-top: 4rem;
  padding-bottom: 4rem;

  img {
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 1024px) {
  .service-container {
    padding: 1rem;
  }
}
