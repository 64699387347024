.sc--main {
  padding: 80px 10rem 75px 10rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  box-sizing: border-box;
  display: block;

  .ct--main {
    display: flex;
    flex-direction: column;
    align-items: center;

    .cmp--text.center {
      text-align: center;
      font-size: 1.5rem;
      margin-bottom: 2rem;

      .h--m.txt--green {
        color: var(--green);
        font-size: 2.5rem;
        margin-bottom: 2.5rem;
      }

      .h--l {
        font-size: 2.2rem;
      }
    }

    .cmp--ic {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      .cmp--step {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1 1 calc(20% - 1rem);
        margin: 0.5rem;
        padding: 1rem;
        text-align: left;
        transition:
          transform 0.5s ease,
          box-shadow 0.5s ease;

        .cmp--stepicon {
          margin-bottom: 1rem;

          .img--stepico {
            width: 9rem;
            height: 9rem;
          }
        }

        .h--s.txt--green {
          color: var(--green);
          font-size: 1.8rem;
          margin-bottom: 0.5rem;
        }

        p {
          font-size: 1.2rem;
          line-height: 1.5;
          text-align: center;
        }
      }

      .cmp--arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        margin: 0;
        transition: transform 0.5s ease;

        i {
          font-size: 2rem;
        }

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  .steps-trenner-img {
    width: 100%;
    max-width: 100%;
    position: relative;
    padding-top: 1rem;
    padding-bottom: 3rem;
  
    img {
      width: 100%;
      max-width: 900px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .header-container {
    .p-3 {
      max-width: 900px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

// Responsive Styles
@media (max-width: 1200px) {
  .sc--main {
    .ct--main {
      .cmp--ic {
        .cmp--step {
          flex: 1 1 calc(50% - 1rem);
        }
        .cmp--arrow {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .sc--main {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

@media (max-width: 768px) {
  .sc--main {
    padding-top: 50px;
    padding-bottom: 50px;

    .ct--main {
      .cmp--text.center {
        .h--m.txt--green {
          font-size: 1.2rem;
        }

        .h--l {
          font-size: 2rem;
        }
      }

      .cmp--ic {
        .cmp--step {
          flex: 1 1 100%;
        }

        .cmp--arrow {
          display: none;
        }
      }
    }
  }
}

// Variables
:root {
  --black: #2e2e2e;
  --green: #03d6ae;
  --white: #ffffff;
  --pink: #ff73bb;
  --gradient-middle: #67a8c2;
  --light-pink: #fc95ce;
  --light-grey: #f3f1f1;
}
