@import "../../variables.scss";

.contact-container {
  margin: 0 auto 6rem;
  max-width: 1640px;

  .icon {
    margin: 2rem auto 1rem;
    width: 7rem;
    height: 0.25rem;
    background-color: $pink;
    border-radius: 3px;
  }

  .contact-section {
    max-width: 1640px;
    display: flex;
    justify-content: space-around;
    gap: 4rem;
    border-radius: 0.5rem;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 1rem;
    }

    .contact-form-wrapper,
    .faq-wrapper {
      flex: 1;
      text-align: left;
    }

    .contact-form-wrapper {
      padding: 2.5rem 1rem 1rem 1rem;

      p {
        font-size: 1.3rem;
        font-weight: 400;
        text-align: center;
      }
    }
  }

  .contact-title-text {
    font-weight: bold;
    margin-bottom: 1rem;
    max-width: 48rem;
    margin: auto;
  }

  h1 {
    color: $green;
    font-size: 2rem;
    font-weight: 700;
  }

  p {
    font-size: 1.15rem;
    font-weight: 100;
    line-height: 1.6;
    margin-bottom: 2rem;
    max-width: 1200px;
    margin: auto;
  }

  @media (max-width: 768px) {
    .contact-title-text {
      padding: 1rem;
    }
  }
}
