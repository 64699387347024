@import "../../variables.scss";

.footer-brand-link {
  cursor: pointer;
  background: transparent;
  color: $white;
  border: 4px solid $light-pink;
  border-radius: 0.4rem;
  display: inline-block;
  text-decoration: none;
  font-size: 1.5rem;
  padding: 1rem 1.5rem 1rem 1rem;
  margin: 0.8rem;
  z-index: 999;

  span {
    padding: 0.7rem;
    font-weight: 300;
    font-size: 3rem;
  }

  &.horizontal {
    transform: rotate(0deg);
  }

  &.vertical {
    transform: rotate(-90deg);
  }

  &.position-left {
    align-self: flex-start;
  }

  &.position-center {
    align-self: center;
  }

  &.position-right {
    align-self: flex-end;
  }
}
