@import "../../variables.scss";

.contact-form-container {
  margin: 0 auto;
  padding: 2rem;
  border-radius: 4px;
  background: $pink;

  @media (max-width: 768px) {
    padding: 1rem;
    width: 100%;
  }

  .thank-you-message {
    text-align: center;

    h2 {
      font-size: 2rem;
      color: $black;
    }

    p {
      font-size: 1.2rem;
      color: $gray;
    }
  }

  .contact-form {
    display: flex;
    flex-direction: column;
    color: $white;

    h2 {
      font-size: 2rem;
      margin-bottom: 1rem;
      text-align: center;
    }

    .form-group {
      margin-bottom: 1.5rem;

      label {
        display: block;
        margin-bottom: 0.5rem;
        float: left;
        text-transform: none;
      }

      input,
      textarea,
      select {
        width: 100%;
        padding: 0.75rem;
        font-size: 1rem;
        border: 1px solid $white;
        border-radius: 5px;
        transition: border-color 0.3s;

        &:focus {
          border-color: $gray;
          outline: none;
        }
      }

      textarea {
        resize: vertical;
        min-height: 150px;
      }

      select {
        background-color: $white;
        color: $black;
      }
    }
  }
}
