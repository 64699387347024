@import "./variables.scss";

body,
html {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  color: $black;
  font-family: $primaryFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
