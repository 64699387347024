@import "../../variables.scss";

.consent-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(135deg, $pink, $green);
  color: $white;
  padding: 1rem;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: transform 0.3s ease-in-out;

  &.hidden {
    transform: translateY(100%);
  }

  &.visible {
    transform: translateY(0);
  }
}

.consent-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin: 0.5rem 0;
  }
}

.consent-button {
  background-color: $white;
  color: $light-green;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: $white;
  }
}
