@import "../../variables.scss";

.navigation-section {
  display: flex;
  justify-content: start;
  margin: 1rem;
  white-space: nowrap;
  border-left: 2px solid $white;
  flex-grow: 1;

  .nav-item {
    color: $white;
    margin-right: auto;
    padding: 0 1rem;

    ul {
      font-size: 2rem;
      padding: 0;
    }

    li {
      list-style: none;
      font-weight: 400;
      margin-bottom: 1rem;

      a {
        display: block;
        text-decoration: none;
        color: $white;
        text-transform: uppercase;
        font-size: 1.3rem;
        font-weight: 100;
        transition:
          color 0.3s ease-in-out,
          transform 0.3s ease-in-out;
        text-align: justify;

        &:hover {
          color: $light-pink;
          transform: scale(1.05);
        }
      }
    }

    @media (min-width: 640px) {
      font-size: 1.5rem;
      padding: 1rem;
    }
  }

  @media (max-width: 905px) {
    border-left: 0 none;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
}
