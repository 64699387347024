@import "../../variables.scss";

.banner {
  position: relative;
  height: 100vh;
  overflow: hidden;
  color: $white;
  margin-top: 0 !important;
  padding-top: 3rem;
  width: 100%;

  .image-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  .parallax {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: right center;
    background-size: cover;
    transition: opacity 0.5s ease-in-out;
    background-attachment: fixed;
    opacity: 0;

    @media (max-width: 768px) {
      background-size: cover;
      background-position: right -200px top;
    }

    &.fade-in {
      opacity: 1;
      z-index: 2;
    }

    &.fade-out {
      opacity: 0;
      z-index: 1;
    }
  }

  .banner-content {
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    text-align: left;
    max-width: 920px;
    padding-left: 12%;

    @media (max-width: 768px) {
      padding-left: 3%;
    }
  }

  .banner-text {
    font-size: 4rem;
    font-family: "Paytone One", sans-serif;
    transition: color 0.5s ease-in-out;

    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }

  .dot {
    color: $green;
  }

  @media (max-width: 768px) {
    button.medium {
      margin-top: 30px;
      padding: 0.6rem 1rem;
      font-size: 0.9rem;
    }
  }
}
