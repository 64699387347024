@import "../../variables.scss";

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  display: block;
  font-weight: 700;
  color: $green;
  margin-bottom: 1rem;
  margin-top: 0;
}

.h1 {
  font-size: 1.5rem;
  line-height: 2rem;
}

.h2 {
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.h3 {
  font-size: 0.875rem;
  line-height: 1.125rem;
}

.h4,
.h5,
.h6 {
  font-size: 0.75rem;
  line-height: 1rem;
}
