@import url("https://fonts.googleapis.com/css?family=Paytone+One&display=swap");
@import url("https://fonts.googleapis.com/css?family=Nunito:400,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Courgette&display=swap");

/* colors */
$blackest: #000;
$black: #777;
$gray: #555;
$white: #f1f1f1;
$green: #00b5a9;
$light-green: #41e2c4;
$pink: #ff73bb;
$light-pink: #fc95ce;

/* fonts */
$primaryFont: "Montserrat", sans-serif;
$secondaryFont: "Nunito", sans-serif;
$thirdFont: "Courgette", cursive;

/* sizes */
$titleFontSize: 100px;

/* global classes */
.title-font {
  font-family: $primaryFont;
  line-height: 130%;
}

.title-font-2 {
  font-family: $secondaryFont;
  line-height: 130%;
}

.title-font-3 {
  font-family: $thirdFont;
  line-height: 130%;
}

.green-title {
  color: $green;
  font-size: 1.5rem;
}

.black-title {
  color: $blackest;
  font-size: 3rem;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
}

.text-start {
  text-align: start;
}

// break-points
$breakpoint-mobile: 576px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1200px;

// other classes
.hidden {
  display: none !important;
}

.v-hidden {
  visibility: hidden;
}

/* Flexbox */

.d-flex {
  display: flex !important;
}

.align-items-start {
  display: flex !important;
  align-items: flex-start !important;
}

.align-items-center {
  display: flex !important;
  align-items: center !important;
}

.align-items-end {
  display: flex !important;
  align-items: flex-end !important;
}

.flex-center {
  display: flex !important;
  align-items: center !important;
}

.flex-column {
  display: flex !important;
  flex-direction: column !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.justify-content-start {
  display: flex !important;
  justify-content: flex-start !important;
}

.justify-content-center {
  display: flex !important;
  justify-content: flex-center !important;
}

.justify-content-end {
  display: flex !important;
  justify-content: flex-end !important;
}

.justify-content-between {
  display: flex !important;
  justify-content: space-between !important;
}

/* Overflow */
.overflow-hidden {
  overflow: hidden !important;
}

/* Margins */
.m-0 {
  margin: 0 !important;
}

/* - top */
.mt-auto {
  margin-top: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1-2 {
  margin-top: 0.5rem !important;
}

.mt-1-4 {
  margin-top: 0.25rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mt-4 {
  margin-top: 4rem !important;
}

.mt-6 {
  margin-top: 6rem !important;

  @media (max-width: 768px) {
    margin-top: 4rem !important;
  }
}

/* - right */
.mr-auto {
  margin-right: auto !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-1-2 {
  margin-right: 0.5rem !important;
}

.mr-1-4 {
  margin-right: 0.25rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mr-2 {
  margin-right: 2rem !important;
}

/* - bottom */
.mb-auto {
  margin-bottom: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1-2 {
  margin-bottom: 0.5rem !important;
}

.mb-1-4 {
  margin-bottom: 0.25rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.mb-6 {
  margin-bottom: 6rem !important;

  @media (max-width: 768px) {
    margin-bottom: 3rem !important;
  }
}

/* - left */
.ml-auto {
  margin-left: auto !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-1-2 {
  margin-left: 0.5rem !important;
}

.ml-1-4 {
  margin-left: 0.25rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.ml-2 {
  margin-left: 2rem !important;
}

/* Paddings */
/* - top */
.pt-0 {
  padding-top: 0 !important;
}

.pt-1-2 {
  padding-top: 0.5rem !important;
}

.pt-1 {
  padding-top: 1rem !important;
}

.pt-2 {
  padding-top: 2rem !important;
}

/* - right */
.pr-0 {
  padding-right: 0 !important;
}

.pr-1-2 {
  padding-right: 0.5rem !important;
}

.pr-1 {
  padding-right: 1rem !important;
}

.pr-2 {
  padding-right: 2rem !important;
}

/* - bottom */
.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1-2 {
  padding-bottom: 0.5rem !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}

.pb-2 {
  padding-bottom: 2rem !important;
}

/* - left */
.pl-0 {
  padding-left: 0 !important;
}

.pl-1-2 {
  padding-left: 0.5rem !important;
}

.pl-1 {
  padding-left: 1rem !important;
}

.pl-2 {
  padding-left: 2rem !important;
}

/* Text */
.text-sm {
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}

.text-md {
  font-size: 0.875rem !important;
  line-height: 1.125rem !important;
}

.text-lg {
  font-size: 1rem !important;
  line-height: 1.25rem !important;
}

.bold {
  font-weight: bold !important;
}

.italic {
  font-style: italic !important;
}

.normal {
  font-weight: normal !important;
}

.underline {
  text-decoration: underline !important;
}

/* Width */
.quarter-width {
  width: 25% !important;
}

.half-width {
  width: 50% !important;
}

.full-width {
  width: 100% !important;
}

.hide-desktop {
  display: none;
}

@media (max-width: 768px) {
  .hide-mobile {
    display: none !important;
  }

  .hide-desktop {
    display: block;
  }
}
