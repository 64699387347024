@import "../../variables.scss";

.impressum-section {
  padding: 2rem;
  background: $white;
  color: $black;

  .impressum-container {
    padding: 2rem;
    background: $white;
    border-radius: 0.8rem;
    box-shadow: 0 0.4rem 0.5rem rgba(0, 0, 0, 0.1);
    transition:
      transform 0.3s ease,
      box-shadow 0.3s ease;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
    }
  }

  .impressum-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: $green;
    margin-bottom: 1.5rem;
  }

  .impressum-content {
    text-align: left;

    h2 {
      font-size: 1.5rem;
      color: $pink;
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;
    }

    p {
      font-size: 1rem;
      line-height: 1.5;
      margin-bottom: 0.5rem;
      color: $black;

      a {
        color: $green;
        text-decoration: none;
        transition: color 0.3s ease;

        &:hover {
          color: $light-green;
          text-decoration: underline;
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 1rem;

    .impressum-title {
      font-size: 2rem;
    }

    .impressum-content {
      h2 {
        font-size: 1.25rem;
      }

      p {
        font-size: 0.875rem;
      }
    }
  }
}
