@import "../../variables.scss";

.custom-button {
  background: $pink;
  color: $white;
  text-transform: uppercase;
  font-weight: 200;
  letter-spacing: 2px;
  border: 2px solid $light-pink;
  border-radius: 0.4rem;
  cursor: pointer;
  transition:
    background-color 0.3s,
    border-color 0.3s;

  &.small {
    padding: 0.5rem 1rem;
    font-size: 0.75rem;
  }

  &.medium {
    padding: 1rem 2.5rem;
    font-size: 1rem;
  }

  &.large {
    padding: 1.5rem 3.5rem;
    font-size: 1.25rem;
  }

  &:hover {
    background: $light-pink;
    border-color: $pink;
    border: 2px solid $pink;
  }
}
