@import "../../variables.scss";

.language-switcher {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  padding: 0 10px;
  color: $white;
  font-size: x-large;
  border: 2px solid $light-pink;
  border-radius: 4px;
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.language-icon {
  background-color: transparent;
}

.language-option {
  cursor: pointer;
}

.language-option.active {
  font-weight: bolder;
  color: $light-pink;
}
