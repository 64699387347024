@import "../../variables.scss";

.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  max-width: 33.333333%;
  width: 33.333333%;

  .card-icon {
    width: 10rem;
    height: 10rem;

    img {
      width: 100%;
      height: 100%;
    }
  }

  h3.green-title {
    font-size: 1.8rem;
    font-weight: 700;
  }

  p {
    font-size: 1.4rem;
    line-height: 1.5;
    flex: 1 1 calc(20% - 1rem);
  }

  @media (max-width: 992px) {
    max-width: 100%;
    width: 100%;
  }
}
