@import "../../variables.scss";

.faq-section {
  margin: 0 auto;
  padding: 1.2rem;
  font-weight: 100;
  color: #777;

  .faq-item {
    border-bottom: 1px solid $gray;
    font-weight: 100;

    .faq-question {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 1rem;
      font-size: 1.3rem;
      transition: color 0.3s ease;

      &:hover {
        color: $pink;
      }

      i {
        margin-right: 0.8rem;
        transition: transform 0.5s ease;
      }
    }

    .faq-answer {
      max-height: 0;
      overflow: hidden;
      transition:
        max-height 0.5s ease,
        opacity 0.5s ease,
        padding-left 0.5s ease;
      opacity: 0;
      padding: 0 0.8rem 0 0;

      p {
        margin: 0 1rem 0.6rem;
      }

      &.open {
        max-height: 80rem;
        opacity: 1;
        padding-left: 2rem;
      }
    }
  }
}
