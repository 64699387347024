@import "../../variables.scss";
@import url("https://fonts.googleapis.com/css?family=Paytone+One&display=swap");
@import url("https://fonts.googleapis.com/css?family=Nunito:400,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Courgette&display=swap");
@import "../../variables.scss";

.header-transparent {
  position: relative;
  height: 0;
  z-index: 1000;

  .container {
    min-width: 100rem;
  }

  .navbar-toggler {
    position: absolute;
    right: 2rem;
    top: 4rem;
    border: none;

    &:focus {
      outline: none !important;
    }

    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' linecap='round' linejoin='round' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    }
  }

  .navbar-collapse.show,
  .collapsing {
    position: absolute;
    right: 0;
    top: 7rem;
    left: 0;
    z-index: 99;
    background-color: #000;
  }

  .navbar-nav .nav-link {
    color: $white;
    padding: 0.5rem 1rem;
    transition: color 0.3s ease;
    font-size: 1.3rem;
    font-weight: 100;
    cursor: pointer;

    &:hover {
      color: $pink;
    }
  }

  @media (max-width: 480px) {
    .navbar {
      padding-top: 5%;

      .navbar-brand {
        font-weight: bold;
        padding: 0.8rem;
      }

      .brand-link {
        margin-top: -0.4rem;
        transform: rotate(0deg) !important;
        position: static !important;
      }
    }
  }

  @media (min-width: 768px) {
    .navbar-nav .nav-link {
      margin-top: 0;
    }

    .navbar.fixed-top {
      position: relative;
    }
  }

  @media (max-width: 1024px) {
    .navbar-nav .nav-link {
      padding: 0.5rem 1rem;
      margin: 1px 2rem;
    }
  }
}
