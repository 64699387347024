.service-card-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  margin: 1rem auto;
}

// .card-container:nth-child(2),
// .card-container:nth-child(5) {
//     padding-top: 6rem;
// }

.header-container {
  max-width: 1200px;
  margin: 0 auto;

  /*p {
    @media (max-width: 650px) {
      padding: 0;
      text-align: justify;
      letter-spacing: 0.5px;
    }
  }*/
}

.service-preview-button {
  padding-bottom: 1.2rem;
}
