.icon {
  height: 0.5rem;
  width: 10rem;
  border-radius: 4px;

  &--left {
    align-self: flex-start;
  }

  &--middle {
    align-self: center;
    margin-left: auto;
    margin-right: auto;
  }

  &--right {
    align-self: flex-end;
    margin-left: auto;
  }
}
