@import "../../variables.scss";

.divider {
  padding: 1rem;
  text-align: center;

  .divider-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    max-width: 1000px;

    .divider-title {
      font-weight: 100;
      letter-spacing: 1.2px;
      max-width: 80rem;
    }

    .divider-subtitle {
      font-size: 1rem;
      letter-spacing: 1.5px;
    }
  }

  .icon {
    width: 10rem;
    height: 0.25rem;
    background-color: $pink;
    border-radius: 3px;
    margin-bottom: 1rem;
  }
}

@media (min-width: 768px) {
  .divider {
    .divider-content {
      .divider-title {
        font-size: 1.2rem;
      }

      .divider-subtitle {
        font-size: 1.1rem;
      }
    }
  }
}

@media (min-width: 1024px) {
  .divider {
    .divider-content {
      .divider-title {
        font-size: 1.5rem;
      }

      .divider-subtitle {
        font-size: 1.25rem;
      }
    }
  }
}
