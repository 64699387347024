@import "../../variables.scss";

.scroll-to-top {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 1000;
}

.scroll-to-top-button {
  background: $light-pink;
  color: $white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 50%;
  cursor: pointer;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition:
    background-color 0.5s,
    transform 0.5s;
  opacity: 0.8;
}

.scroll-to-top-button:hover {
  transform: scale(1.1);
}
