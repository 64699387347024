@import "../../variables.scss";

.about-section {
  padding: 0 1rem;
  background: $white;
  color: $black;
  text-align: center;
  margin: auto;
  height: 100%;

  .about-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    @media (max-width: 820px) {
      flex-direction: column-reverse;
    }
  }

  .text-section {
    flex: 1;
    padding: 1rem;
    z-index: 1;
    text-align: justify;
    margin: auto 9rem;

    .txt--aboutmini,
    .txt--abouttitle {
      font-weight: 100;
      line-height: 1.6;
      font-size: 1.2rem;
      letter-spacing: 0.8px;
      margin-bottom: 3rem;
      opacity: 0;
      transform: translateY(20px);
      transition:
      opacity 0.6s ease-out,
      transform 0.6s ease-out;

      &.visible {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .txt--abouttitle {
      text-align: left;
      line-height: 1.4;
      font-size: 2rem;
      font-weight: 400;
      /*background: $green;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;*/

      span.pink {
        color: $pink;
      }

      span.small {
        font-size: 1.6rem;
      }
    }

    .icon {
      height: 0.25rem;
    }
  }

  .image-section {
    flex: 1;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    background: url("../../assets/images/sneek.webp") no-repeat center center;
    background-size: cover;

    .img-logo-miniabout {
      display: none;
    }

    &.parallax {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        z-index: 1;
      }
    }
  }

  @media (max-width: 650px) {
    .about-container {
      flex-direction: column;
    }
    .txt--abouttitle {
      font-size: 0.8rem;
    }
    .text-section {
      text-align: center;
      margin: auto;
      padding: 1rem;
    }
  }

  @media (max-width: 980px) {
    .text-section {
      margin: auto;
    }
  }
}
