@import "../../variables.scss";

.carousel-container {
  margin: 0 auto;
  padding: 1.2rem 5rem;
  background: #000;

  .slick-slider {
    .slick-arrow {
      background: $pink;
      border-radius: 1rem;
    }
    .slick-list {
      .slick-track {
        .slick-slide {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .slick-dots {
      bottom: 0.8rem;

      li {
        button:before {
          font-size: 1rem;
          color: $white;
        }

        &.slick-active button:before {
          color: $pink;
        }
      }
    }
  }

  .testimonial {
    text-align: center;
    padding: 2rem;
    @media screen and (max-width: 480px) {
      padding: 1rem;
    }

    .testimonial-text {
      font-size: 1.2rem;
      font-style: italic;
      color: #f3f3f3;
      @media screen and (max-width: 480px) {
        font-size: 1rem;
      }
    }

    .testimonial-name {
      font-size: 2rem;
      color: $pink;
      margin-top: 0.8rem;
      @media screen and (max-width: 480px) {
        font-size: 1.3rem;
      }
    }
  }
}
