@import "../../variables.scss";

.about-us-section {
  padding: 0 1rem;
  background: $white;
  color: $black;
  text-align: center;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: space-evenly;

  @media (max-width: 768px) {
    text-align: center;
  }

  .img-container {
    flex: 1;
    position: relative;
    overflow: hidden;

    width: 50%;
    z-index: 1;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 0;
    }
  }

  .about-us-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1.2rem;
    margin: 0 auto;
    max-width: 1100px;

    @media (max-width: 820px) {
      flex-direction: column;
    }
  }

  .txt--about {
    flex: 1;
    padding: 1rem;
    margin: 10rem 12rem;
    z-index: 1;
    text-align: left;

    @media (max-width: 1024px) {
      margin: 3rem 1rem 1rem 1rem;
    }

    @media (max-width: 768px) {
      text-align: center;
    }

    .txt--aboutmini,
    .txt--abouttitle {
      font-weight: 100;
      line-height: 1.6;
      margin-bottom: 2rem;
      font-size: 1.3rem;
      opacity: 0;
      transform: translateY(20px);
      transition:
        opacity 0.6s ease-out,
        transform 0.6s ease-out;

      &.visible {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .txt--abouttitle {
      font-size: 2rem;
      font-weight: 400;
      line-height: 1.4;
      margin-bottom: 3rem;
      color: $green;

      span.pink {
        color: $pink;
      }

      span.small {
        font-size: 1.6rem;
      }

      .icon {
        height: 0.25rem;
      }

      @media (max-width: 768px) {
        font-size: 1.3rem;

        span.small {
          font-size: 1.1rem;
        }
      }
    }
  }
}
