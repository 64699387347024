.section-footer {
  max-width: 100%;
  background-image: url("../../assets/images/wider.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .footer-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    white-space: nowrap;
    padding: 3rem 1rem;

    .image-section {
      display: flex;
      flex-grow: 4;
      justify-content: flex-end;
      position: relative;
    }
  }
}

// Media Queries for Responsiveness
@media (max-width: 768px) {
  .section-footer {
    background-position: center;

    .footer-container {
      flex-direction: column;
      text-align: center;

      .image-section {
        justify-content: center;
        padding-right: 0;
        margin-bottom: 1rem;
        top: 0;
        position: static;
      }
    }
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .section-footer {
    .footer-container {
      flex-direction: row;
      flex-wrap: wrap;
      text-align: center;

      .image-section {
        flex-grow: 1;
        justify-content: center;
        padding-right: 0;
      }
    }
  }
}

@media (min-width: 1025px) {
  .section-footer {
    .footer-container {
      flex-direction: row;

      .image-section {
        padding-right: 10rem;
      }
    }
  }
}
